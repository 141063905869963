import React, {useContext, useState, useEffect} from 'react';
import ReactLoading from 'react-loading';
import { ThemeContext } from '../../contexts/ThemeContext';
// test
// const darJewelleryHostnames = ["qc.darjewellery.com", "darjewellery.com", "localhost","mydarjewelry.com"];
// const tarangHostnames = ["qc.tarangarts.com", "tarangarts.com"];

// const getHostnameType = (hostname) => {
//   if (darJewelleryHostnames.includes(hostname)) {
//     return 'darJewellery';
//   } else if (tarangHostnames.includes(hostname)) {
//     return 'tarangArts';
//   }
//   return 'default';
// };

const LoadingAnimation = () => {
  const theme = useContext(ThemeContext);
  const [splashLogo, setSplashLogo] = useState('');
  // const hostname = window.location.hostname;
  // const hostnameType = getHostnameType(hostname);

  useEffect(() => {
      if (theme.splashLogo && theme.splashLogo !== "")  {
        setSplashLogo(theme.splashLogo);
      }
    }, [theme]);


  if(splashLogo) {
    return (
          <div className="flex justify-center items-center py-40">
            <img src={splashLogo} alt="Loading" className="w-48 h-48 object-contain" />
          </div>
        );
  } else {
    return (
      <div className="w-full h-full flex justify-center items-center py-40">
        <ReactLoading type="spinningBubbles" color="#000000" height={'10%'} width={'10%'} />
      </div>
    );
  }

  // switch (hostnameType) {
  //   case 'darJewellery':
  //     return (
  //       <div className="flex justify-center items-center py-40">
  //         <img src="https://ik.imagekit.io/6r88tgrnc/Pre-comp%20logo%201.gif?updatedAt=1711605278807" alt="Loading" className="w-48 h-48 object-contain" />
  //       </div>
  //     );
  //   case 'tarangArts':
  //     return (
  //       <div className="flex justify-center items-center py-40">
  //         <img src="https://ik.imagekit.io/6r88tgrnc/Tarang%20Logo.gif?updatedAt=1709085303066" alt="Tarang Arts" className="w-48 h-48 object-contain" />
  //       </div>
  //     );
  //   default:
  //     return (
  //       <div className="w-full h-full flex justify-center items-center py-40">
  //         <ReactLoading type="spinningBubbles" color="#000000" height={'10%'} width={'10%'} />
  //       </div>
  //     );
  // }
};

export default LoadingAnimation;
